<template>
  <div class="home">
    <div class="header">
      <div class="div_top header_top flex">
        <span class="title">数据概览</span>
        <div class="right flex">
          <a-radio-group v-model="allValue" @change="timeChange(allValue, 1)">
            <a-radio-button
              v-for="(item, index) in timeArr"
              :key="index"
              :value="item.value"
            >
              {{ item.label }}
            </a-radio-button>
          </a-radio-group>
          <a-range-picker @change="onChange" v-model="pickerDate"></a-range-picker>
        </div>
      </div>
      <div class="header_bottom flex">
        <div class="client flex">
          <div class="flex">
            <span class="title_small">客户数量</span>
          </div>

          <div class="client_con flex">
            <div class="flex">
              <img class="imgs" src="@/assets/imgs/client1.png" alt="" />
              <span class="">全部客户<br /><span class="txt yellow">{{$utils.numberToWan(corp.all)}}</span></span>
            </div>
            <div class="flex">
              <img class="imgs" src="@/assets/imgs/client2.png" alt="" />
              <span>新增客户<br /><span class="txt orange">{{$utils.numberToWan(corp.new)}}</span></span>
            </div>
            <div class="flex">
              <img class="imgs" src="@/assets/imgs/client3.png" alt="" />
              <span>跟进客户<br /><span class="txt blue">{{$utils.numberToWan(corp.follow_log)}}</span></span>
            </div>
          </div>
        </div>

        <div class="order">
          <div class="flex">
            <span class="title_small">合同数</span>
          </div>
          <div class="flex" style="border-right: 2px solid #f4f6f6">
            <img class="imgs" src="@/assets/imgs/order.png" alt="" />
            <span>已成交合同 <br /><span class="txt yellow">{{$utils.numberToWan(order.num)}}</span></span>
          </div>
        </div>
        <div class="order">
          <div class="flex">
            <span class="title_small">成交金额</span>
          </div>
          <div class="flex">
            <img class="imgs" src="@/assets/imgs/money.png" alt="" />
            <span>已成交合同金额 <br /><span class="txt orange">{{$utils.numberToWan(order.amount)}}</span></span>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="bottom_box" :style="{height: windowHeight}"> -->
    <div class="bottom_box">
      <div class="div_con task">
        <div class="div_top task_top flex">
          <span class="title">待办任务</span>
          <div class="right flex" @click="goWeb('task/taskList2')">更多</div>
        </div>
        <div class="cont_box">
          <a-list :data-source="task.slice(0, 3)">
            <a-list-item slot="renderItem" slot-scope="item">
              <div class="task_bottom">
                <div class="flex task_up">
                  <span class="task_title">{{ item.type }}</span>
                  <!-- <span class="blue">进行中（0/5）</span> -->
                </div>
                <div class="task_content">{{ item.task_content }}</div>
                <div class="flex task_down">
                  <span>{{ item.start }} ~ {{ item.end }}</span>
                  <span>处理人：{{ item.creater_uid }}</span>
                </div>
              </div>
            </a-list-item>
          </a-list>
        </div>
      </div>
      <div class="div_con ranking">
        <div class="div_top flex">
          <span class="title">销售排行榜</span>
          <div class="right flex">
            <a-radio-group
              v-model="rankValue"
              @change="timeChange(rankValue, 2)"
            >
              <a-radio-button
                v-for="(item, index) in timeArr"
                :key="index"
                :value="item.value"
              >
                {{ item.label }}
              </a-radio-button>
            </a-radio-group>
            <a-range-picker @change="onChange2" v-model="pickerDate2"></a-range-picker>
          </div>
          <div class="right" @click="goWeb('datas')">更多</div>
        </div>
        <div class="ranking_bottom">
          <!-- <div class="ranking_up">
						<span>新增客户数</span>
						<span>排名从低到高</span>
					</div> -->
          <a-list :data-source="rank.order">
            <a-list-item slot="renderItem" slot-scope="item, index">
              <div class="flex ranking_content">
                <span v-if="index < 3">
                  <img v-if="index == 0" src="@/assets/imgs/one.png" alt="" />
                  <img
                    v-else-if="index == 1"
                    src="@/assets/imgs/two.png"
                    alt=""
                  />
                  <img
                    v-else-if="index == 2"
                    src="@/assets/imgs/three.png"
                    alt=""
                  />
                </span>
                <span class="ranking_num" v-else>{{ index + 1 }}</span>
				<img :src="item.avatar" style="width: 50px;height: 50px;">
                <div style="width: 120px;">
					<span>{{item.staff}}</span>
				</div>
				<div style="width: 120px;">
					<span :class="[index<3? 'yellow' : '']">{{item.amount}}元</span>
				</div>
              </div>
            </a-list-item>
          </a-list>
        </div>
      </div>

      <!-- <div class="div_con dynamic">
				<div class="div_top flex">
					<span class="title">平台动态</span>
					<a href="https://scrm.jctmj.cn/index.php/index/index/news.html" target="_blank"><div class="right flex">更多</div></a>
				</div>
				<div class="dynamic_bottom">
					<div class="flex dynamic_content" v-for="(item,index) in news" :key="index">
						<div class="dynamic_title">
							<span class="red" v-if="index == 0">{{index+1}}</span>
							<span class="yellow" v-if="index == 1">{{index+1}}</span>
							<span class="blue" v-if="index == 2">{{index+1}}</span>
							<span class="con txt_over">{{item.title}}</span>
						</div>
						<span>{{item.created}}</span>
					</div>
				</div>
			</div> -->
    </div>
    <div class="support">
      技术支持：晋城同盟军网络
    </div>
  </div>
</template>
 
<script>
// import APP from "@/App";
// import $ from "jquery";
import { requestXml, getDateTime } from "../../assets/js/request";
var moment = require("moment");
export default {
  data() {
    return {
      windowHeight: "",
      corp: {},
      order: {},
      // 待办任务
      task: [],
      taskType: [
        "打电话",
        "发短信",
        "发微信",
        "发邮件",
        "分享文章",
        "分享表单",
        "分享文件",
        "分享商品",
        "客户拜访",
        "其他",
      ],
      taskStatus: ["取消", "新建", "跟进中", "完成"],
      // 排行榜
      start: [],
      end: [],
      rank: [],
      allValue: 3,
	pickerDate: [],
	pickerDate2:[],
      rankValue: 3,
      timeArr: [
        { label: "今日", value: 1 },
        { label: "本周", value: 2 },
        { label: "本月", value: 3 },
        { label: "全年", value: 4 },
      ],news: []};
  },
  mounted: function () {
    this.windowHeight = document.body.clientHeight - 430 + "px";
    this.getTask();
   
    this.start = moment().startOf("month").format("x");
    this.end = moment().endOf("month").format("x");
    this.getHome();
    this.getRank();
  },
  methods: {
    //更多 页面跳转
    goWeb(path) {
      this.$router.push({
        path: "/scrm_pc/" + path,
      });
    },

    // 获取首页数据
    getHome() {
      requestXml(
        "/scrm/Statistics/getHomeDate",
        "POST",
        (res) => {
          console.log(res);
			this.corp = res.corp;
			this.order = res.order;
        },
        { start: this.start, end: this.end }
      );
    },

    // 获取待办任务
    getTask() {
      requestXml(
        // "/scrm/Task/getMyList",
        '/scrm/Task/getMyFollowedList',
        "POST",
        (res) => {
          for (let i = 0; i < res.list.length; i++) {
            res.list[i].start = getDateTime(res.list[i].start,3);
            res.list[i].end = getDateTime(res.list[i].end,3);

            res.list[i].status = this.taskStatus[Number(res.list[i].status)];
            res.list[i].type = this.taskType[Number(res.list[i].type - 1)];
          }

          this.task = res.list;
          // this.task = "";
        },
        { form: "myfollowed" }
      );
    },
    // 设置开始时间0:0:0 结束时间23:59:59
    setTime(time, type) {
      if (type == 1) {
        time.setHours(0);
        time.setMinutes(0);
        time.setSeconds(0);
      } else {
        time.setHours(23);
        time.setMinutes(59);
        time.setSeconds(59);
      }
      return time.getTime();
    },
    // 本周 本月 全年
    timeChange(val, type) {
      if (val == 1) {
        this.start = moment().startOf("day").format("x");
        this.end = moment().endOf("day").format("x");
      } else if (val == 2) {
        this.start = moment().startOf("week").add(1, "day").format("x");
        this.end = moment().endOf("week").add(1, "day").format("x");
      } else if (val == 3) {
        this.start = moment().startOf("month").format("x");
        this.end = moment().endOf("month").format("x");
      } else {
        this.start = moment().startOf("year").format("x");
        this.end = moment().endOf("year").format("x");
      }
      if (type == 2) {
		this.pickerDate2=[];
        this.getRank();
      } else {
		this.pickerDate=[];
        this.getHome();
      }
    },
    onChange(value) {
	let that=this;that.allValue='';
      this.start = this.setTime(value[0]._d, 1);
      this.end = this.setTime(value[1]._d);
      this.getHome();
    },
    onChange2(value) {
	this.rankValue='';
      this.start = this.setTime(value[0]._d, 1);
      this.end = this.setTime(value[1]._d);
      this.getRank();
    },
    // 获取排行榜
    getRank() {
      requestXml(
        "/scrm/Statistics/getRanking",
        "POST",
        (res) => {
          // 经营日报
          this.rank = res;
        },
        { start: this.start, end: this.end }
      );
    },
    // // 获取平台动态
    // getNews() {
    //     requestXml("/index/index/news?scrm","POST",(res) => {
    // 		for(let i=0;i<res.length;i++){
    //             res[i].created = getDateTime(res[i].created);
    //         }
    // 		this.news = res;
    //     })
    // },
  },
};
</script>
<style scoped>
.support{
  color: #999;
  text-align: center;
  padding-top: 25px;
}
a {
  color: #000;
}
.txt {
  font-size: 26px;
  float: left;
}
.red {
  color: #f01010;
}
.yellow {
  color: #feaa0f;
}
.orange {
  color: #ff6c0c;
}
.blue {
  color: #36a3fe;
}
.home {
  width: 100%;
  margin: 0 auto;
  height: calc(100vh - 120px);
  background-color: #f3f3f3;
  padding: 30px 2vw;
  text-align: left;
}
/* 去掉单选框组合的边框 */
.home .ant-radio-button-wrapper {
  border: 0;
  padding: 0 5px;
}
.home .ant-radio-button-wrapper::before {
  width: 0;
}
.home
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  box-shadow: 0 0;
}
.header {
  width: 100%;
  height: 300px;
  background-color: #fff;
}
.title {
  color: #1a1a1a;
  font-size: 18px;
  font-weight: bold;
}
.div_top {
  padding: 0 25px;
  justify-content: space-between;
  height: 66px;
  line-height: 66px;
  border-bottom: 1px solid #eeeeee;
}
.div_top .right {
  cursor: pointer;
  align-items: center;
}
.div_top .right:hover {
  color: #36a3fe;
}
/* .div_top .right span{
		display: inline-block;
		margin-right: 10px;
	} */
.header .header_bottom {
  color: #202020;
  /* font-size: 16px; */
  font-weight: 600;
  justify-content: space-around;
}
.header .header_bottom .title_small {
  font-size: 18px;
  color: #138fff;
  margin: 40px 0;
}
.header .header_bottom .client {
  flex-direction: column;
  width: 50%;
  padding-left: 15px;
}
.header .header_bottom .client .client_con {
  border-right: 2px solid #f4f6f6;
}
.header .header_bottom .client .client_con div {
  width: 33.3%;
}
.header .header_bottom .order {
  /* flex-direction: column;
		align-items: center; */
  width: 288px;
}
.header .header_bottom .imgs {
  width: 56px;
  height: 56px;
  margin-right: 10px;
}
.bottom_box {
  display: flex;
}
.div_con {
  height: 100%;
  background-color: #fff;
  margin: 10px 10px 0 0;
  float: left;
}
.div_con:last-child {
  margin-right: 0;
}
.task,
.ranking {
  height: 450px;
}
.task {
  width: 45%;
}
.ranking {
  width: 66%;
}
.dynamic {
  /* width: 26%; */
  width: calc(100% - 1380px);
}
.cont_box {
  height: 380px;
  overflow-y: scroll;
}
.cont_box::-webkit-scrollbar {
  width: 0 !important;
}
/* 任务 */
.task .task_bottom {
  margin: 10px 20px 0;
  padding: 15px;
  border: 1px solid #eeeeee;
  border-radius: 2px;
  width: 100%;
}
.task .task_up {
  justify-content: space-between;
}
.task .task_title {
  color: #202020;
  font-size: 18px;
}
.task .task_content {
  /* margin: 20px 0; */
  color: #666;
}
.task .task_down {
  justify-content: space-between;
  color: #999;
}
/* 排行榜 */
.ranking .ranking_bottom {
  margin: 20px 20px 0;
  height: 350px;
  overflow-y: scroll;
}
.ranking .ranking_up {
  height: 42px;
  line-height: 42px;
  background: #ebf6ff;
  border-radius: 2px;
  color: #161616;
}
.ranking .ranking_content {
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  line-height: 60px;
  font-size: 18px;
  color: #161616;
  cursor: pointer;
}
.ranking .ranking_content img {
  width: 40px;
  height: 46px;
}
.ranking .ranking_content .ranking_num {
  width: 42px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  color: #1f90ff;
  background: #d2e9ff;
  font-size: 22px;
  border-radius: 50%;
}
/* 动态 */
.dynamic .dynamic_content {
  padding: 0 20px;
  height: 35px;
  line-height: 35px;
  color: #666;
  /* font-size: 16px; */
  justify-content: space-between;
  cursor: pointer;
}
.dynamic .dynamic_content:hover {
  color: #27a5ff;
}
.dynamic .dynamic_content .dynamic_title {
  display: flex;
  width: 75%;
}
.dynamic .dynamic_content .dynamic_title .con {
  display: inline-block;
  width: 95%;
  margin-left: 5px;
}
</style>
 